import { Password } from '@/model/Password'

function formatToTracking(password: Password) {
  const { lowerUpperCase, numbers, specialChars } = password

  let pwStrength = `${password.score},${password.value.length}`
  if (lowerUpperCase === true) pwStrength = pwStrength + `,M`
  if (numbers === true) pwStrength = pwStrength + `,N`
  if (specialChars === true) pwStrength = pwStrength + `,S`
  return pwStrength
}

export { formatToTracking }
