import * as zxcvbn from 'zxcvbn'
import { Password } from '@/model/Password'

function validatePassword(s: string): Password {
  const result = zxcvbn.default(s)
  const password: Password = {
    value: s,
    length: s.length >= 10,
    // contain special characters first: all except letters OR (list of char which are count as \w)
    specialChars: s.length > 0 && (!/^[\w]+$/.test(s) || /[,_]/.test(s)),
    numbers: /\d/.test(s),
    lowerUpperCase: /(.*[a-z].*)(.*[A-Z].*)|(.*[A-Z].*)(.*[a-z].*)/.test(s),
    score: result.score,
  }
  return password
}

export { validatePassword }
