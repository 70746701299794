import { validatePassword } from './lib/validatePassword'
import { formatToTracking } from './lib/formatToTracking'

class PasswordScoreCalculation {
  private inputPassword: HTMLInputElement
  private inputScore: HTMLInputElement

  constructor(passwordInputId: string) {
    this.inputPassword = document.getElementById(passwordInputId) as HTMLInputElement
    if (this.inputPassword) {
      this.inputScore = this.createScoreInput(this.inputPassword)
      this.applyKeyChangeListener(this.inputPassword)
    } else {
      // otherwise typescript complains
      this.inputScore = document.createElement('input')
    }
  }

  applyKeyChangeListener(dom: HTMLElement) {
    dom.addEventListener('input', (e: Event) => {
      const value = (e.target as any)?.value
      if (value) {
        this.inputScore.value = formatToTracking(validatePassword(value))
      }
    })
  }

  createScoreInput(dom: HTMLElement) {
    const scoreNode = document.createElement('input')
    scoreNode.setAttribute('type', 'hidden')
    scoreNode.setAttribute('name', 'score')
    dom.parentNode?.appendChild(scoreNode)
    return scoreNode
  }
}

// for direct HTML access
// eslint-disable-next-line @typescript-eslint/no-extra-semi
;(window as any).PasswordScoreCalculation = PasswordScoreCalculation

export { PasswordScoreCalculation }
